import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation, useWindowDimensions} from '@/hooks';
import Carousel from '@/components/molecules/carousel';
import {Link} from 'react-router-dom';
import styles from './card-shelf.module.scss';
import ArticleCard from '@/components/molecules/article-card';
import MediaPlaceholder from '@/assets/images/media-placeholder@2x.png';
import Image from '@/components/atoms/image';
import {ImageSizes} from '@/constants/image-sizes.constant';

const ShelfCardComponent = ({title, description, list, showMoreLink, sponsor, className, itemsDesktop = 3, itemsTablet = 2, itemsMobile = 1, partialVisibilityGutterMobile, Card = DEFAULT_CARD}) => {
  const {isMobile} = useWindowDimensions();
  const {t} = useTranslation();

  return (
    <div className={`${styles.cgCardShelf} ${className}`}>
      <span id={showMoreLink?.replace(/^\/|\/$/g, '').replace(/\//g, '-')} style={{scrollMarginTop: '100px'}}/>
      <div className={`${styles.cgCardShelf__header} header`}>
        <h2 className={`${styles.cgCardShelf__title} title`}>{title}</h2>
        {showMoreLink && <Link className={styles.cgCardShelf__link} to={showMoreLink}>{t('moleculeTranslations.seeAll')}</Link>}
        {sponsor &&
        <div className={`${styles.cgCardShelf__sponsored} mr-0`}>
          <p className={`${styles.cgCardShelf__sponsoredText}`}>{t('podcastsPageTranslations.sponsoredBy')}: </p>
          <Image alt={sponsor?.name || ''} className={`${styles.cgCardShelf__sponsoredLogo}`} size={ImageSizes.FULL} src={sponsor?.logo || sponsor?.image?.location}></Image>
        </div>}
      </div>
      {!!description && <p className={styles.cgCardShelf__description}>{description}</p>}
      {list && <Carousel
        itemsDesktop={itemsDesktop}
        itemsTablet={itemsTablet}
        itemsMobile={itemsMobile}
        partialVisibilityGutterDesktop={0}
        partialVisibilityGutterMobile={list.length > 1 ? partialVisibilityGutterMobile : 0}
        slidesToSlideMobile={1}
        slidesToSlideDesktop={1}
        className={styles.cgCardShelf__list}
        partialVisible={isMobile}
      >
        {list?.map((item, index) => (
            <Card key={index} value={item} />
        ))}
      </Carousel>}
    </div>
  )
};

const getAuthor = (authors) => {
  if (!authors?.length) {
    return {};
  }
  const author = authors[0];
  return {
    title: author?.title,
    image: author?.avatar?.location
  }
}

const DEFAULT_CARD = ({ value }) => {
  const author = getAuthor(value.authors)

  return (
    <ArticleCard
      className={styles.cgCardShelf__card}
      title={value.title}
      author={author?.title || ''}
      authorImg={author?.image || ''}
      bodyText={value.excerpt}
      img={value.image || value.listing_image.url || value.listing_image}
      imgAlt={`${value?.title} listing image`}
      imageDefault={MediaPlaceholder}
      link={value.url?.url || value?.url}
      authorCount={value.authors?.length}
      collection={value.collection}
      type={value.type}
      readTime={value.completion_time}
      sectionRef={value?.section_uuid || ''}
    />
  )
}

DEFAULT_CARD.propTypes = {
  value: PropTypes.any,
}

// Card Prop Should be a Component with param value.
ShelfCardComponent.propTypes = {
  description: PropTypes.string,
  list: PropTypes.array,
  showMoreLink: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  sponsor: PropTypes.object,
  className: PropTypes.string,
  itemsDesktop: PropTypes.number,
  itemsTablet: PropTypes.number,
  itemsMobile: PropTypes.number,
  partialVisibilityGutterMobile: PropTypes.number,
  Card: PropTypes.func
};

export default ShelfCardComponent;
